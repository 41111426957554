import React from "react";
import { NavLink } from "react-router-dom";
import Loader from "../../components/loaders";
import routes from "../../navigation/routes";

export default function Card(props: any) {
  let { activeJobs } = props;
  return (
    <div className="career-details">
      {!activeJobs ? (
        <Loader
          backgroundColor="rgba(150,150,150,0.22)"
          type="spinner"
          loading={true}
        />
      ) : activeJobs.length <= 0 ? (
        <div>No job found.</div>
      ) : (
        activeJobs.map((x: any) => {
          return (
            <div className="careers">
              <NavLink to={`${routes.jobDetail}?jobId=${x.Jobid}`}>
                <div className="card" key={x.Jobid}>


                  <div className="card-title">
                    {x.ExternalJobTitle}
                  </div>

                  <div className="jobdesc primary">
                    <div className="information">
                      {x.Education}
                    </div>
                    {x.IsExperience ? (
                      <div className="information">
                        <span>
                          {`${x.Experience} ${x.Experience <= 1 ? "Year" : "Years"
                            } Experience`}
                        </span>
                        {/* <span>{x.Department}</span> */}
                      </div>
                    ) : (
                      <div className="information">No experience necessary</div>
                    )}
                  </div>
                  <div className="jobdesc secondary">
                    <div className="information"><span className="info-label">Published: </span>{x.PublisheDate}</div>
                    {x.EmployementType &&
                      <div className="information"><span className="info-label"> Contract Type:</span>
                        {x.EmployementType}

                      </div>
                    }
                  </div>
                  <div className="jobdesc deadline ">
                    <div className="information"><span className="info-label">Apply before </span>{x.EndDate}</div>
                    <span className="remaining-days">{x.DaysRemaining}</span>

                    {/* <NavLink to={`${routes.jobApply}?jobId=${x.Jobid}`}> */}

                    {/* </NavLink> */}




                  </div>
                  <div className="jobdesc buttonwrapper">
                    <button type="button" className="apply-btn">
                      Job Detail
                    </button>

                  </div>
                </div>
              </NavLink>
            </div >
          );
        })
      )}
    </div >

  );
}
