import React from "react";
import { Link } from "react-router-dom";
import routes from "../routes";

function JobListBar(props: any) {
  const { companyInformation } = props;

  return (
    <div className="cover-banner">
      <div className="cover-image">
        <img src={window.location.origin + "/" + companyInformation.CoverImage} alt="logo" />
      </div>
      <div className="cover-features">
        <div className="container">
          <div className="logo-cover">
            <img src={window.location.origin + "/" + companyInformation.CompanyLogo} alt="logo" />
          </div>
          <div className="welcome-text">{companyInformation.WelcomeText}</div>
        </div>
      </div>

    </div>

  );
}

export default JobListBar;
